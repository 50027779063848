import {apiCall} from './apiCall';
import {toast} from 'react-toastify';

export const getSupplierOrderTrackings = (idSupplierOrder) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        resolve(response);
      };

      const onFailure = (error) => {
        reject(error);
      };

      const obj = {
        url: '/api/supplierordertrackings/get/' + idSupplierOrder,
        method: 'get',
        data: {},
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Errore durante il recupero dei tracking',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const createSupplierOrderTracking = (
  idCarrier,
  idSupplierOrder,
  tracking
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        toast.success('Tracking creato con successo');
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error('Errore durante la creazione del tracking');
        reject(error);
      };

      const obj = {
        url: '/api/supplierordertrackings/create',
        method: 'post',
        data: {idCarrier, idSupplierOrder, tracking},
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Errore durante la creazione del tracking',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const updateSupplierOrderTracking = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        toast.success('Tracking aggiornato con successo');
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error("Errore durante l'aggiornamento del tracking");
        reject(error);
      };

      const obj = {
        url: '/api/supplierordertrackings/edit/' + id,
        method: 'put',
        data: data,
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: "Errore durante l'aggiornamento del tracking",
      };

      dispatch(apiCall(obj));
    });
  };
};

export const deleteSupplierOrderTracking = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        toast.success('Tracking eliminato con successo');
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error("Errore durante l'eliminazione del tracking");
        reject(error);
      };

      const obj = {
        url: '/api/supplierordertrackings/destroy/' + id,
        method: 'delete',
        data: {},
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: "Errore durante l'eliminazione del tracking",
      };

      dispatch(apiCall(obj));
    });
  };
};
