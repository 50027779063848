import {apiCall} from './apiCall';
import {toast} from 'react-toastify';

export const getCarriersNew = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error('Error retrieving carriers');
        reject(error);
      };

      const obj = {
        url: '/api/carriers',
        method: 'get',
        data: {},
        onSuccess: onThen,
        onFailure: onFailure,
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Error retrieving carriers',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const getCarrier = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error('Error retrieving carrier details');
        reject(error);
      };

      const obj = {
        url: `/api/carriers/${id}`,
        method: 'get',
        data: {},
        onSuccess: onThen,
        onFailure: onFailure,
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Error retrieving carrier details',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const createCarrier = (name) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        toast.success('Carrier created successfully');
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error('Error creating carrier');
        reject(error);
      };

      const obj = {
        url: '/api/carriers',
        method: 'post',
        data: {name},
        onSuccess: onThen,
        onFailure: onFailure,
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Error creating carrier',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const updateCarrier = (id, name) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        toast.success('Carrier updated successfully');
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error('Error updating carrier');
        reject(error);
      };

      const obj = {
        url: `/api/carriers/${id}`,
        method: 'put',
        data: {name},
        onSuccess: onThen,
        onFailure: onFailure,
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Error updating carrier',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const deleteCarrier = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        toast.success('Carrier deleted successfully');
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error('Error deleting carrier');
        reject(error);
      };

      const obj = {
        url: `/api/carriers/${id}`,
        method: 'delete',
        data: {},
        onSuccess: onThen,
        onFailure: onFailure,
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Error deleting carrier',
      };

      dispatch(apiCall(obj));
    });
  };
};
