import {toast} from 'react-toastify';
import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';
import {closeModal} from './modals';
import {setTableParameters} from './shared';

export const setOrders = (orders) => {
  return {
    type: actionTypes.SET_ORDERS_LIST,
    orders: orders,
  };
};

export const setUnimportedOrders = (unimportedOrders) => {
  return {
    type: actionTypes.SET_UNIMPORTED_ORDERS_LIST,
    unimportedOrders: unimportedOrders,
  };
};

export const changeShippingNumber = (ev) => {
  return {
    type: actionTypes.SET_SHIPPING_NUMBER,
    shippingNumber: ev.target.value,
  };
};

export const setOrderResources = (data) => {
  switch (data.type) {
    case 'customers':
      return {
        type: actionTypes.SET_ORDER_RESOURCES_CUSTOMERS,
        customers: data.customers,
      };
    case 'products':
      return {
        type: actionTypes.SET_ORDER_RESOURCES_PRODUCTS,
        products: data.products,
      };
    case 'addresses':
      return {
        type: actionTypes.SET_ORDER_RESOURCES_ADDRESSES,
        customers: data.addresses,
      };
    case 'startData':
      return {
        type: actionTypes.SET_ORDER_RESOURCES_START,
        countries: data.countries,
        carriers: data.carriers,
        currencies: data.currencies,
        payments: data.payments,
        states: data.states,
        owners: data.owners,
      };
    default:
      break;
  }
};

export const setOrderProperty = (data) => {
  switch (data.type) {
    case 'customer':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_CUSTOMER,
        customer: data.customer,
        addresses: data.addresses,
      };
    case 'product':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_PRODUCT,
        product: data.product,
      };
    case 'products':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_PRODUCTS,
        products: data.products,
      };
    case 'address':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_ADDRESS,
        address: data.address,
      };
    case 'carrier':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_CARRIER,
        carrier: data.carrier,
      };
    case 'currency':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_CURRENCY,
        currency: data.currency,
      };
    case 'payment':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_PAYMENT,
        payment: data.payment,
      };
    case 'state':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_STATE,
        state: data.state,
      };
    case 'idOwner':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_OWNER,
        idOwner: data.idOwner,
      };
    case 'coupon':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_COUPON,
        coupon: data.coupon,
      };
    case 'totalShipping':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_TOTALSHIPPING,
        totalShipping: data.totalShipping,
      };
    case 'totalWrapping':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_TOTALWRAPPING,
        totalWrapping: data.totalWrapping,
      };
    case 'totalDiscounts':
      return {
        type: actionTypes.SET_ORDER_PROPERTY_TOTALDISCOUNTS,
        totalDiscounts: data.totalDiscounts,
      };
    default:
      break;
  }
};

export const fetchResources = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setOrderResources(response.data));
    };

    let obj = {
      url: '/api/orders/fetchresources',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};

export const createOrderCustomer = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setOrderProperty(response.data));
    };

    let obj = {
      url: '/api/orders/createcustomer',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Cliente creato e settato con successo!',
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};

export const createOrderAddress = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setOrderProperty(response.data));
    };

    let obj = {
      url: '/api/orders/createaddress',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Indirizzo creato e settato con successo!',
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};

export const setCurrentStep = (desiredStep) => {
  return {
    type: actionTypes.SET_ORDER_NEXT_STEP,
    step: desiredStep,
  };
};

export const nextStep = (
  desiredStep,
  currentStep,
  availableSteps,
  allSteps
) => {
  return (dispatch) => {
    if (availableSteps.includes(desiredStep)) {
      dispatch(setCurrentStep(desiredStep));
    } else {
      switch (desiredStep) {
        case 1:
          toast.error('Impossibile proseguire, selezionare un cliente');
          break;
        case 2:
          toast.error('Impossibile proseguire, selezionare un indirizzo');
          break;
        case 3:
          toast.error('Impossibile proseguire, selezionare almeno un prodotto');
          break;
        case 4:
          toast.error('Impossibile proseguire, dati mancanti');
          break;
        default:
          toast.error('Errore sconosciuto, contattare un amministratore');
          break;
      }
    }
  };
};

export const setCreateOrderAddress = (id) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setOrderProperty(response.data));
    };

    let obj = {
      url: '/api/orders/setcreateorderaddress/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Indirizzo settato con successo!',
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};

export const setCreateOrderCustomer = (id) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setOrderProperty(response.data));
    };

    let obj = {
      url: '/api/orders/setcreateordercustomer/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Cliente settato con successo!',
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};

export const setCreateOrderProduct = (id, usedCombinationsArray) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setOrderProperty(response.data));
    };

    let obj = {
      url: '/api/orders/setcreateorderproduct/' + id,
      method: 'post',
      data: {usedCombinations: usedCombinationsArray},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto aggiunto con successo!',
      errorMessage: "Errore durante l'aggiunta del prodotto",
    };

    dispatch(apiCall(obj));
  };
};

export const updateShippingNumber = (id, shippingNumber) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/orders/${id}/shipping-number`,
        method: 'put',
        data: {shippingNumber},
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: 'Shipping number updated successfully',
        errorMessage: 'Error updating shipping number',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const setCreateOrderProducts = (data) => {
  return (dispatch) => {
    dispatch(setOrderProperty(data));
  };
};

export const setSelectedCountry = (country) => {
  return {
    type: actionTypes.SET_ORDER_RESOURCE_COUNTRY,
    country: country,
  };
};

export const setSelectedProvince = (province) => {
  return {
    type: actionTypes.SET_ORDER_RESOURCE_PROVINCE,
    province: province,
  };
};

export const setSelectedOrder = (data) => {
  return {
    type: actionTypes.SET_SELECTED_ORDER,
    selectedOrder: data.selectedOrder,
  };
};

export const resetCreateOrderState = () => {
  return {
    type: actionTypes.RESET_CREATE_ORDER_STATE,
  };
};

export const setNewOrderCreated = (data) => {
  return {
    type: actionTypes.SET_NEW_ORDER_CREATED,
    orderId: data.order?.id,
  };
};

export const createOrder = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(resetCreateOrderState());
      let data = new FormData();
      data.set('type', 'start');
      dispatch(fetchResources(data));
      dispatch(setNewOrderCreated(response.data));
    };

    let obj = {
      url: '/api/orders/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ordine creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const createSingleLabel = (
  page,
  idOrder,
  idBox = 1,
  dimensions = {width: 0, height: 0, depth: 0}
) => {
  //, carrierLabel) => {
  return (dispatch) => {
    let onThen = (response) => {
      const data = response.data;

      if (page === 'order') {
        dispatch(getSingleOrder(idOrder));
      } else if (page === 'closing' && data && data.label) {
        window.open(data.label, '_blank');
      }
    };

    let obj = {
      url: '/api/orders/createlabel/' + idOrder,
      method: 'post',
      data: {
        box: {
          id: parseInt(idBox),
          width: parseInt(dimensions.width),
          height: parseInt(dimensions.height),
          depth: parseInt(dimensions.depth),
        },
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {
        toast.error("Errore durante la creazione dell'etichetta");
      },
      label: '',
      headers: {},
      successMessage: "Etichetta dell'ordine creata con successo",
      errorMessage: "Errore durante la creazione dell'etichetta",
    };

    dispatch(apiCall(obj));
  };
};

export const getSingleOrder = (id) => {
  return (dispatch) => {
    let onThen = (response) => {
      if (response?.data?.selectedOrder?.unrecognized.length > 0) {
        toast.warning('Prodotti non riconosciuti rilevati');
      }
      dispatch(setSelectedOrder(response.data));
    };

    let obj = {
      url: '/api/orders/show/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: "Errore durante il recupero dell'ordine",
    };

    dispatch(apiCall(obj));
  };
};

export const fetchOrdersNew = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/orders/shownew`,
        method: 'post',
        data: data,
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        headers: {},
        successMessage: 'Ordini caricati correttamente',
        errorMessage: 'Errore nel caricamento degli ordini',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const changeOrderState = (idState, idOrder) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/orders/changestate`,
        method: 'post',
        data: {idState, idOrder},
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: 'Ordine modificato con successo',
        errorMessage: 'Errore durante la modifica',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const editOrder = (data, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/orders/edit/${id}`,
        method: 'put',
        data: data,
        onSuccess: (response) => {
          resolve(response.data);
        },
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: 'Ordine modificato con successo',
        errorMessage: "Errore durante la modifica dell'ordine",
      };
      dispatch(apiCall(obj));
    });
  };
};

export const destroyOrder = (data, id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getOrders());
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/orders/destroy/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ordine eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const getOrders = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setOrders(response.data.orderList));
    };

    let obj = {
      url: '/orders',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
      lambda: true,
    };

    dispatch(apiCall(obj));
  };
};

export const getUnimportedOrders = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      //dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setUnimportedOrders(response.data.orders));
    };

    let obj = {
      url: '/orders/unimported',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
      lambda: true,
    };

    dispatch(apiCall(obj));
  };
};

export const importPsOrder = (data) => {
  return (dispatch) => {
    let onThen = () => {
      toast.success('Scaricamento ordini messo in coda, ETA 5 minuti');
    };

    let obj = {
      url: '/api/orders/download-ps',
      method: 'post',
      data: data,
      onSuccess: () => onThen(),
      onFailure: () => {
        toast.error('Errore');
      },
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const resetNewOrderCreated = () => {
  return {
    type: actionTypes.RESET_NEW_ORDER_CREATED,
  };
};

export const showTrackingAndLabel = (id) => {
  return (dispatch) => {
    let onThen = (response) => {
      //dispatch(setTrackingAndLabel(response.data.response));
      window.open(response.data.response.s3_label, '_blank');
    };

    let obj = {
      url: '/api/orders/show/tracking/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Etichetta non trovata',
    };

    dispatch(apiCall(obj));
  };
};
