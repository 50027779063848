import {apiCall} from './apiCall';
import {openModal} from './modals';
import * as actionTypes from './actionTypes';
import {editSelectedSupplierOrder} from './supplierOrders';
import {toast} from 'react-toastify';

export const createSupplierOrderProduct = (id, idOrder) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(openModal('supplierOrder', 'attach', idOrder));
    };

    let obj = {
      url: '/api/supplierorderproducts/create',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto inserito con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const syncSupplierOrderPrices = (idOrder, sameSupplier) => {
  return (dispatch) => {
    let onThen = (response) => {
      let arrayToIterate = response.data?.supplierOrder?.products || [];
      arrayToIterate.forEach((product) => {
        dispatch(
          editSelectedSupplierOrder(
            parseFloat(product.pricePerUnit),
            'pricePerUnit',
            product.id
          )
        );
      });
      //dispatch(getSupplierOrder(idOrder));
    };

    let obj = {
      url: '/api/supplierorderproducts/syncprices',
      method: 'post',
      data: {
        idOrder: idOrder,
        sameSupplier: sameSupplier,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prezzi sincronizzati con successo',
      errorMessage: 'Errore durante la sincronizzazione',
    };

    dispatch(apiCall(obj));
  };
};

export const destroySupplierOrderProduct = (id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch({
        type: actionTypes.UNSET_SELECTED_SUPPLIER_ORDER_PRODUCT,
        id: id,
      });
    };

    let obj = {
      url: '/api/supplierorderproducts/destroy/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const destroySupplierOrderProductNew = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        // Simply resolve the promise with the response.
        resolve(response);
      };

      const onFailure = (error) => {
        // Optionally show an error toast here if needed.
        reject(error);
      };

      const obj = {
        url: '/api/supplierorderproducts/destroy/' + id,
        method: 'post',
        data: {},
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: 'Prodotto eliminato con successo',
        errorMessage: 'Errore durante la modifica',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const relocateSupplierProduct = (
  idSku,
  idSupplierOrder,
  idShelf,
  quantity
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        const data = response.data ? response.data : response;
        if (data.notMovedLocation === 0) {
          toast.success('Prodotto ubicato correttamente');
        } else {
          toast.warning(
            `${data.notMovedLocation} prodotto deve rimanere in TEMP`
          );
        }
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error('Error relocating product');
        reject(error);
      };

      const obj = {
        url: '/api/supplierorderproducts/relocateproduct',
        method: 'post',
        data: {idSku, idSupplierOrder, idShelf, quantity},
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Errore durante il relocation del prodotto',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const fetchRelocateData = (idOrderDetail) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        toast.success('Relocation data fetched successfully');
        resolve(response);
      };

      const onFailure = (error) => {
        toast.error('Error fetching relocation data');
        reject(error);
      };

      const obj = {
        url: '/api/supplierorderproducts/fetchrelocatedata',
        method: 'post',
        data: {idOrderDetail},
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Errore durante il fetching dei dati di relocation',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const updateSupplierOrderProduct = (rows) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        resolve(response);
      };

      const onFailure = (error) => {
        reject(error);
      };

      const obj = {
        url: '/api/supplierorderproducts/update',
        method: 'post',
        data: {rows},
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: "Errore durante l'aggiornamento",
      };

      dispatch(apiCall(obj));
    });
  };
};

export const getAverageProductCostPrice = (idSkus) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const onThen = (response) => {
        resolve(response.data);
      };

      const onFailure = (error) => {
        reject(error);
      };

      const obj = {
        url: '/api/supplierorderproducts/get/averagecostprice',
        method: 'post',
        data: {idSkus},
        onSuccess: (response) => onThen(response),
        onFailure: (error) => onFailure(error),
        label: '',
        headers: {},
        successMessage: null,
        loader: false,
        noPendingRequest: true,
        errorMessage: 'Errore durante il recupero del costo medio del prodotto',
      };

      dispatch(apiCall(obj));
    });
  };
};
