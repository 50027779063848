import React from 'react';
import {connect} from 'react-redux';
import {isAllowed} from '../../shared/functions';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
// Material
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import {makeStyles} from '@material-ui/core/styles/';
// Images
import house from '../../images/DrawerIcons/house.png';
import euro from '../../images/DrawerIcons/euro.png';
import products from '../../images/DrawerIcons/products.png';
import book from '../../images/DrawerIcons/book.png';
import group from '../../images/DrawerIcons/group.png';
import books from '../../images/DrawerIcons/books.png';
import gears from '../../images/DrawerIcons/gears.png';
import brand from '../../images/DrawerIcons/brand.png';
import descriptionModels from '../../images/DrawerIcons/descriptionModels.png';
import warehouse from '../../images/DrawerIcons/warehouse.png';
import equalizer from '../../images/DrawerIcons/equalizer.png';
import distribution from '../../images/DrawerIcons/distribution.png';
import checklist from '../../images/DrawerIcons/checklist.png';
import ordersList from '../../images/DrawerIcons/ordersList.png';
import movement from '../../images/DrawerIcons/movement.png';
import shelvingsViewer from '../../images/DrawerIcons/products.png'; // TEMP
import shelves from '../../images/DrawerIcons/shelves.png';
import market from '../../images/DrawerIcons/market.png';
import categories from '../../images/DrawerIcons/categories.png';
import manufacture from '../../images/DrawerIcons/manufacture.png';
import courier from '../../images/DrawerIcons/courier.png';
import cloud from '../../images/DrawerIcons/cloud.png';
import magnifyingGlass from '../../images/DrawerIcons/magnifying-glass.png';
//import prestashopLogo from '../../images/DrawerIcons/prestashopLogo.png';
import logoCD from '../../images/DrawerIcons/logo_cd.png';
import sysadmin from '../../images/DrawerIcons/sysadmin.png';
import unlink from '../../images/DrawerIcons/unlink.png';
import entrepreneur from '../../images/DrawerIcons/entrepreneur.png';
import cdbaseproduct from '../../images/DrawerIcons/cd_baseproduct.png';
import cdproduct from '../../images/DrawerIcons/cd_product.png';
import cdcreationsapproval from '../../images/DrawerIcons/cd_productapproval.png';
import cdcreationsmanagement from '../../images/DrawerIcons/cd_creationsmanage.png';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerLink: {
    textDecoration: 'none',
    color: 'black',
  },
  nested: {
    backgroundColor: '#ECECEC',
    paddingLeft: 50,
  },
  shadowImage: {
    WebkitFilter: 'drop-shadow(1px 1px 1px #737373)',
  },
});

function SwipeableTemporaryDrawer(props) {
  const {user} = useAuthenticator((context) => [context.user]);

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const returnBadgeCalc = (type) => {
    switch (type) {
      case 'unexportedProducts':
        return props?.systemStatus?.status?.unexportedProducts || 0;
      case 'unimportedOrders':
        return props?.systemStatus?.status?.unimportedOrders || 0;
      case 'products':
        return props?.systemStatus?.status?.unexportedProducts || 0;
      case 'orders':
        return props?.systemStatus?.status?.unimportedOrders || 0;
      default:
        break;
    }
  };

  // * Creator Dashboard
  const creatorDashboardSection = [];

  if (
    isAllowed(
      ['admin', 'wadmin', 'commercial', 'worker', 'superworker'],
      user?.signInUserSession?.idToken?.payload['cognito:groups']
    )
  ) {
    // * Creator Dashboard title
    creatorDashboardSection.push({
      text: 'Creator Dashboard',
      icon: (
        <img
          width={30}
          className={classes.shadowImage}
          src={logoCD}
          alt="Creator Dashboard"
        />
      ),
      children: {},
    });

    // * Creator Dashboard see custom creations
    creatorDashboardSection[0].children = {
      ...creatorDashboardSection[0].children,
      createProduct: {
        text: 'Custom Creations',
        link: '/creatordashboard/customcreations',
        icon: (
          <img
            width={30}
            className={classes.shadowImage}
            src={cdproduct}
            alt="customcreations"
          />
        ),
      },
    };

    // * Creator Dashboard see custom creations approval
    creatorDashboardSection[0].children = {
      ...creatorDashboardSection[0].children,
      creationsApproval: {
        text: 'Creations Approval',
        link: '/creatordashboard/creationsapproval',
        icon: (
          <img
            width={30}
            className={classes.shadowImage}
            src={cdcreationsapproval}
            alt="creationsApproval"
          />
        ),
      },
    };

    // * Creator Dashboard see custom creations management
    creatorDashboardSection[0].children = {
      ...creatorDashboardSection[0].children,
      creationsManagement: {
        text: 'Stampa prodotti',
        link: '/creatordashboard/creationsmanagement',
        icon: (
          <img
            width={30}
            className={classes.shadowImage}
            src={cdcreationsmanagement}
            alt="creationsManagement"
          />
        ),
      },
    };

    if (
      isAllowed(
        ['admin', 'wadmin'],
        user?.signInUserSession?.idToken?.payload['cognito:groups']
      )
    ) {
      // * Creator Dashboard see base products
      creatorDashboardSection[0].children = {
        ...creatorDashboardSection[0].children,
        baseProducts: {
          text: 'Bases Management',
          link: '/creatordashboard/bases',
          icon: (
            <img
              width={30}
              className={classes.shadowImage}
              src={cdbaseproduct}
              alt="baseproducts"
            />
          ),
        },
      };
    }
  }

  const menuList = [
    ...(isAllowed(
      ['admin', 'wadmin', 'commercial', 'worker', 'superworker'],
      user?.signInUserSession?.idToken?.payload['cognito:groups']
    )
      ? [
          {
            text: 'Home',
            link: '/',
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={house}
                alt="house"
              />
            ),
          },
          {
            text: 'Statistiche',
            badge: 0,
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={movement}
                alt="movement"
              />
            ),
            children: {
              OrdersProfits: {
                text: 'Ordini',
                link: '/stats/profit/orders',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              SkuProfits: {
                text: 'Sku',
                link: '/stats/profit/skus',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              SkuStats: {
                text: 'Inventario',
                link: '/stats/inventorystats',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              CustomersStats: {
                text: 'Clienti',
                link: '/stats/customerstats',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              WilliamSales: {
                text: 'William',
                link: '/stats/william-sales',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              SkuStockThresholds: {
                text: 'Controllo Stock',
                link: '/stats/sku-stock-thresholds',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
            },
          },
          {
            text: 'Ordini',
            badge: returnBadgeCalc('orders'),
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={euro}
                alt="euro"
              />
            ),
            children: {
              ordersList: {
                text: 'Lista',
                link: '/orders',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={ordersList}
                    alt="ordersList"
                  />
                ),
              },
              preorders: {
                text: 'Prevendite',
                link: '/preorders',
                icon: (
                  <EventOutlinedIcon
                    style={{width: '30px', height: '30px'}}
                    className={classes.shadowImage}
                    alt="preorders"
                  />
                ),
              },
              downloadOrders: {
                text: 'Download ordini manuale',
                link: '/orders/download',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={market}
                    alt="newOrder"
                  />
                ),
              },
              unimportedOrders: {
                text: 'Non importati',
                badge: returnBadgeCalc('unimportedOrders'),
                link: '/orders/unimported',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={unlink}
                    alt="unlink"
                  />
                ),
              },
              newOrder: {
                text: 'Crea nuovo ordine',
                link: '/orders/create',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={market}
                    alt="newOrder"
                  />
                ),
              },
              ordersTag: {
                text: 'Tag Ordini',
                link: '/orderstag',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={ordersList}
                    alt="ordersList"
                  />
                ),
              },
            },
          },
          {
            text: 'Prodotti',
            badge: returnBadgeCalc('products'),
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={products}
                alt="products"
              />
            ),
            children: {
              product: {
                text: 'Anagrafica',
                link: '/products',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={book}
                    alt="book"
                  />
                ),
              },
              skus: {
                text: 'Sku',
                link: '/skus',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={books}
                    alt="books"
                  />
                ),
              },
              attributes: {
                text: 'Attributi',
                link: '/attributes',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={gears}
                    alt="gears"
                  />
                ),
              },
              brands: {
                text: 'Marche',
                link: '/brands',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={brand}
                    alt="brand"
                  />
                ),
              },
              unexportedProducts: {
                text: 'Non esportati',
                badge: returnBadgeCalc('unexportedProducts'),
                link: '/products/unexported',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={unlink}
                    alt="unlink"
                  />
                ),
              },
              descriptionModels: {
                text: 'Modelli desc.',
                link: '/description/models',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={descriptionModels}
                    alt="descriptionModels"
                  />
                ),
              },
            },
          },
          {
            text: 'Magazzino',
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={warehouse}
                alt="warehouse"
              />
            ),
            children: {
              product: {
                text: 'Ordini fornitore',
                link: '/warehouse/arrivals',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={distribution}
                    alt="distribution"
                  />
                ),
              },
              skus: {
                text: 'Elenco',
                link: '/warehouse/locations',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={checklist}
                    alt="checklist"
                  />
                ),
              },
              attributes: {
                text: 'Movimentazioni',
                link: '/warehouse/movements',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              shelvingsViewer: {
                text: 'Ubicazioni',
                link: '/shelvings/viewer',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={shelvingsViewer}
                    alt="shelvingsViewer"
                  />
                ),
              },
              shelvings: {
                text: 'Scaffalatura',
                link: '/shelvings/manager',
                level: 4,
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={shelves}
                    alt="shelves"
                  />
                ),
              },
              carts: {
                text: 'Carrelli',
                link: '/carts',
                level: 3,
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={market}
                    alt="market"
                  />
                ),
              },
              findSku: {
                text: 'Localizza Sku',
                link: '/find/sku',
                level: 3,
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={magnifyingGlass}
                    alt="magnifyingGlass"
                  />
                ),
              },
            },
          },
          {
            text: 'Categorie',
            link: '/categories',
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={categories}
                alt="categories"
              />
            ),
          },
          {
            text: 'Fornitori',
            link: '/suppliers',
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={manufacture}
                alt="manufacture"
              />
            ),
          },
          {
            text: 'Centro Spedizioni',
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={courier}
                alt="courier"
              />
            ),
            children: {
              shipmentCenter: {
                text: 'Centro sped.',
                link: '/shipmentcenter',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={courier}
                    alt="courier"
                  />
                ),
              },
              bordero: {
                text: 'Borderó',
                link: '/bordero',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={ordersList}
                    alt="courier"
                  />
                ),
              },
              manualLabel: {
                text: 'Crea etichetta manuale',
                link: '/manuallabel',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={ordersList}
                    alt="courier"
                  />
                ),
              },
            },
          },
          {
            text: 'Inventario',
            link: '/inventory',
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={products}
                alt="inventory"
              />
            ),
          },
        ]
      : []),
    ...(isAllowed(
      ['brand_manager'],
      user?.signInUserSession?.idToken?.payload['cognito:groups']
    )
      ? [
          {
            text: 'Dashboard',
            link: '/brand/dashboard',
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={entrepreneur}
                alt="entrepreneur"
              />
            ),
          },
          {
            text: 'Statistiche',
            badge: 0,
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={movement}
                alt="movement"
              />
            ),
            children: {
              OrdersProfits: {
                text: 'Ordini',
                link: '/stats/profit/orders',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              SkuProfits: {
                text: 'Sku',
                link: '/stats/profit/skus',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              SkuStats: {
                text: 'Inventario',
                link: '/stats/inventorystats',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
              CustomersStats: {
                text: 'Clienti',
                link: '/stats/customerstats',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={movement}
                    alt="movement"
                  />
                ),
              },
            },
          },
          {
            text: 'Ordini',
            badge: 0,
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={euro}
                alt="euro"
              />
            ),
            children: {
              ordersList: {
                text: 'Lista',
                link: '/orders',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={ordersList}
                    alt="ordersList"
                  />
                ),
              },
            },
          },
        ]
      : []),
    ...creatorDashboardSection,
    ...(isAllowed(
      ['admin'],
      user?.signInUserSession?.idToken?.payload['cognito:groups']
    )
      ? [
          {
            text: 'Contabilità',
            level: 4,
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={sysadmin}
                alt="sysadmin"
              />
            ),
            children: {
              RegistrazioneIva: {
                text: 'Registrazione IVA',
                link: '/accounting/vat',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={sysadmin}
                    alt="RegistrazioneIva"
                  />
                ),
              },
              StatsVendita: {
                text: 'Statistiche vendite',
                link: '/accounting/sales',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={sysadmin}
                    alt="StatsVendita"
                  />
                ),
              },
              ValoreMagazzino: {
                text: 'Valore Magazzino',
                link: '/accounting/warehouse/values',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={sysadmin}
                    alt="ValoreMagazzino"
                  />
                ),
              },
            },
          },
        ]
      : []),
    ...(isAllowed(
      ['admin', 'wadmin'],
      user?.signInUserSession?.idToken?.payload['cognito:groups']
    )
      ? [
          {
            text: 'Martketplace',
            level: 3,
            link: '/marketplaces',
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={cloud}
                alt="cloud"
              />
            ),
          },
          {
            text: 'Sistema',
            level: 4,
            icon: (
              <img
                width={30}
                className={classes.shadowImage}
                src={sysadmin}
                alt="sysadmin"
              />
            ),
            children: {
              controlpanel: {
                text: 'Pannello di controllo',
                link: '/system/panel',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={equalizer}
                    alt="equalizer"
                  />
                ),
              },
              groups: {
                text: 'Gruppi',
                link: '/system/groups',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={group}
                    alt="group"
                  />
                ),
              },
              owners: {
                text: 'Proprietari',
                link: '/system/owners',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={entrepreneur}
                    alt="entrepreneur"
                  />
                ),
              },
              users: {
                text: 'Utenti',
                link: '/system/users',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={group}
                    alt="group"
                  />
                ),
              },
              apiKeys: {
                text: 'Chiavi API',
                link: '/system/apikeys',
                icon: (
                  <img
                    width={30}
                    className={classes.shadowImage}
                    src={equalizer}
                    alt="equalizer"
                  />
                ),
              },
            },
          },
        ]
      : []),
  ];
  const [open, setOpen] = React.useState({});

  const handleClick = (index) => {
    let obj = {...open};
    if (obj[index]) {
      delete obj[index];
      setOpen(obj);
    } else {
      obj[index] = true;
      setOpen(obj);
    }
  };

  const renderIcon = (item) => {
    if (item.badge) {
      return (
        <ListItemIcon>
          <Badge badgeContent={item.badge} color="primary">
            {item.icon}
          </Badge>
        </ListItemIcon>
      );
    } else {
      return <ListItemIcon>{item.icon}</ListItemIcon>;
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({...state, [anchor]: open});
    setOpen({});
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menuList.map((el, index) => {
          if (el.children) {
            return (
              <React.Fragment key={index}>
                <ListItem
                  button
                  className={classes.drawerLink}
                  onClick={() => handleClick(index)}
                >
                  {renderIcon(el)}
                  <ListItemText primary={el.text} />
                  {open[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={open[index] ? true : false}
                  timeout="auto"
                  unmountOnExit
                >
                  {Object.values(el.children).map((item, idx) => {
                    return (
                      <List key={idx} component="div" disablePadding>
                        <Link
                          to={item.link}
                          className={classes.drawerLink}
                          onClick={toggleDrawer(anchor, false)}
                        >
                          <ListItem button className={classes.nested}>
                            {renderIcon(item)}
                            <ListItemText primary={item.text} />
                          </ListItem>
                        </Link>
                      </List>
                    );
                  })}
                </Collapse>
              </React.Fragment>
            );
          } else {
            return (
              <Link
                key={index}
                to={el.link}
                className={classes.drawerLink}
                onClick={toggleDrawer(anchor, false)}
              >
                <ListItem button>
                  {renderIcon(el)}
                  <ListItemText primary={el.text} />
                </ListItem>
              </Link>
            );
          }
        })}
      </List>
    </div>
  );
  return (
    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          {anchor === 'left' && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={toggleDrawer(anchor, true)}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          )}

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  const {auth, systemStatus} = state;
  return {
    auth,
    systemStatus,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwipeableTemporaryDrawer);
