import {apiCall} from './apiCall';

// Azione per ottenere tutti gli order tags
export const fetchOrderTags = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order-tags`,
        method: 'get',
        // Il controller restituisce { name, data, success } → usiamo response.data.data
        onSuccess: (response) => resolve(response.data.data),
        onFailure: (error) => reject(error),
        headers: {},
        successMessage: null,
        errorMessage: 'Errore nel caricamento degli order tags',
      };
      dispatch(apiCall(obj));
    });
  };
};

// Azione per creare un nuovo order tag
export const createOrderTag = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order-tags`,
        method: 'post',
        data: data,
        // Il controller restituisce { name, data, success } → usiamo response.data.data
        onSuccess: (response) => resolve(response.data.data),
        onFailure: (error) => reject(error),
        headers: {},
        successMessage: 'Order tag creato correttamente',
        errorMessage: "Errore nella creazione dell'order tag",
      };
      dispatch(apiCall(obj));
    });
  };
};

// Azione per modificare il campo "name" di un order tag esistente
export const editOrderTag = (id, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order-tags/${id}`,
        method: 'put',
        data: data,
        // Anche qui il payload utile è in response.data.data
        onSuccess: (response) => resolve(response.data.data),
        onFailure: (error) => reject(error),
        headers: {},
        successMessage: 'Order tag aggiornato correttamente',
        errorMessage: "Errore nell'aggiornamento dell'order tag",
      };
      dispatch(apiCall(obj));
    });
  };
};

// Azione per eliminare un order tag
export const deleteOrderTag = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order-tags/${id}`,
        method: 'delete',
        // Per delete, la risposta utile è in response.data.data (in questo caso solitamente vuota)
        onSuccess: (response) => resolve(response.data.data),
        onFailure: (error) => reject(error),
        headers: {},
        successMessage: 'Order tag eliminato correttamente',
        errorMessage: "Errore nell'eliminazione dell'order tag",
      };
      dispatch(apiCall(obj));
    });
  };
};

// Funzione per attaccare un tag ad un ordine
export const attachTagToOrder = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order-tags/attach`,
        method: 'post',
        data, // { idOrder, idTag }
        onSuccess: (response) => resolve(response.data.data),
        onFailure: (error) => reject(error),
        headers: {},
        successMessage: null,
        errorMessage: "Errore nell'attacco del tag all'ordine",
      };
      dispatch(apiCall(obj));
    });
  };
};

// Funzione per staccare un tag da un ordine
export const detachTagToOrder = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order-tags/detach`,
        method: 'post',
        data, // { idOrder, idTag }
        onSuccess: (response) => resolve(response.data.data),
        onFailure: (error) => reject(error),
        headers: {},
        successMessage: null,
        errorMessage: "Errore nello stacco del tag dall'ordine",
      };
      dispatch(apiCall(obj));
    });
  };
};

// Funzione per recuperare i tag di un ordine specifico
export const getOrderTags = (idOrder) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order-tags/getOrderTags/${idOrder}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data.data),
        onFailure: (error) => reject(error),
        headers: {},
        successMessage: null,
        errorMessage: "Errore nel caricamento dei tag dell'ordine",
      };
      dispatch(apiCall(obj));
    });
  };
};
