import {apiCall} from './apiCall';
import {closeModal} from './modals';

export const getOrderDetails = (idOrder) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order/details/${idOrder}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving order details',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getMoreOrderDetails = (idOrder, idSkus, idOrders) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order/details/${idOrder}/more`,
        method: 'post',
        data: {idSkus, idOrders},
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving more order details',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const editOrderDetail = (data, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let onThen = (response) => {
        dispatch(closeModal());
        resolve(response); // Risolvi la Promise con la risposta
      };

      let obj = {
        url: '/api/orderdetails/edit/' + id,
        method: 'post',
        data: data,
        onSuccess: (response) => onThen(response),
        onFailure: (error) => reject(error), // Rifiuta la Promise in caso di errore
        label: '',
        headers: {},
        successMessage: 'Modifica con successo',
        errorMessage: 'Errore durante la modifica',
      };

      dispatch(apiCall(obj));
    });
  };
};

export const destroyOrderDetail = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/orderdetails/destroy/${id}`,
        method: 'delete',
        data: {},
        onSuccess: (response) => {
          resolve(response.data);
        },
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: 'Prodotto rimosso successo',
        errorMessage: 'Errore durante la modifica',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const createOrderDetail = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: '/api/orderdetails/create',
        method: 'post',
        data: data,
        onSuccess: (response) => {
          resolve(response.data);
        },
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: 'Prodotto aggiunto successo',
        errorMessage: 'Errore durante la modifica',
      };
      dispatch(apiCall(obj));
    });
  };
};
