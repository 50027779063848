import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';

export const setCustomerSearch = (data) => {
  return {
    type: actionTypes.SET_CUSTOMER_SEARCH,
    result: data.result,
  };
};

export const searchCustomer = (string) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setCustomerSearch(response.data));
    };

    let obj = {
      url: '/api/customer/search',
      method: 'post',
      data: {string: string},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      noPendingRequest: true,
      successMessage: null,
      errorMessage: 'Errore durante la ricerca',
    };

    dispatch(apiCall(obj));
  };
};

export const editCustomer = (data, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/customer/edit/${id}`,
        method: 'post',
        data: data,
        onSuccess: (response) => {
          resolve(response.data);
        },
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: 'Cliente modificato con successo',
        errorMessage: 'Errore durante la modifica',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getCustomer = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/customer/${id}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving customer',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getCustomerNotes = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/customers/${id}/notes`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving customer notes',
      };
      dispatch(apiCall(obj));
    });
  };
};
