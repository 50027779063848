import {apiCall} from './apiCall';
import {closeModal} from './modals';

export const editAddress = (data, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/address/edit/${id}`,
        method: 'post',
        data: data,
        onSuccess: (response) => {
          dispatch(closeModal());
          resolve(response.data);
        },
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: 'Indirizzo modificato con successo',
        errorMessage: 'Errore durante la modifica',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getAddress = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let obj = {
        url: `/api/address/${id}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: "Errore durante il recupero dell'indirizzo",
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getBillingAddress = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let obj = {
        url: `/api/billing/address/${id}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: "Errore durante il recupero dell'indirizzo",
      };
      dispatch(apiCall(obj));
    });
  };
};
