import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {toast} from 'react-toastify';

export const setOrders = (data) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_ORDERS,
    orders: data.orders,
    ordersStates: data.ordersStates,
  };
};

export const setPickingBatches = (data) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_PICKING_BATCHES,
    pickingBatchesEnabled: data.pickingBatchesEnabled,
    pickingBatches: data.pickingBatches,
  };
};
export const setPickingBatchesForCarts = (data) => {
  return {
    type: actionTypes.SET_PICKING_BATCHES_FOR_CARTS,
    pickingBatchesForCarts: data.results,
  };
};

export const setPickingCurrentPosition = (idCart, currentPosition) => {
  return {
    type: actionTypes.SHIPMENTCENTER_SET_CURRENT_POSITION,
    idCart: idCart,
    currentPosition: currentPosition,
  };
};

export const putInParcel = (id) => {
  return {
    type: actionTypes.SHIPMENTCENTER_PUT_IN_PARCEL,
    id: id,
  };
};

export const emptyPickProductRow = () => {
  return {
    type: actionTypes.SHIPMENTCENTER_EMPTY_PICK_PRODUCT_ROW,
  };
};

export const putAllInParcel = () => {
  return {
    type: actionTypes.SHIPMENTCENTER_PUT_ALL_IN_PARCEL,
  };
};

export const setNeedQuit = () => {
  return {
    type: actionTypes.SET_NEED_QUIT_FROM_CLOSING,
  };
};

export const setClosingOrder = (data) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_CLOSINGORDER,
    order: data.reservation,
    uuid: data.uuid,
  };
};

export const setSelectedBox = (idBox) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_SELECTEDBOX,
    idBox: idBox,
  };
};

export const resetClosingOrder = () => {
  return {
    type: actionTypes.RESET_SHIPMENTCENTER_CLOSINGORDER,
  };
};

export const markAsShipped = (
  uuid,
  idBox,
  idOrder,
  startTime,
  endTime,
  products,
  needQuit,
  pickingCartId = 0
) => {
  console.info('enter markasshipped');
  return (dispatch) => {
    console.info('enter dispatch in return');

    let data = {
      uuid: uuid,
      idBox: idBox,
      idOrder: idOrder,
      startTime: startTime,
      endTime: endTime,
      products: products,
      type: 'shipped',
      needQuit: needQuit,
      pickingCartId,
    };

    let onThen = () => {
      console.info('OnThen, markAsShipped');
      dispatch(resetClosingOrder());
      if (needQuit) {
        dispatch(setClosingCart(false, false));
      }
    };

    let obj = {
      url: '/api/shipmentcenter/closing/markorder',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ordine contrassegnato come spedito!',
      errorMessage: 'Errore nel segnare spedito',
    };
    console.info('Sto per fare dispatch');
    dispatch(apiCall(obj));
    console.info('dopo dispatch');
  };
};

export const markAsProblems = (
  uuid,
  idOrder,
  startTime,
  endTime,
  trackingCode,
  needQuit
) => {
  return (dispatch) => {
    let data = {
      uuid: uuid,
      idOrder: idOrder,
      startTime: startTime,
      endTime: endTime,
      trackingCode: trackingCode,
      type: 'problems',
      needQuit: needQuit,
    };

    let onThen = () => {
      toast.info('Ordine contrassegnato come problematico');
      dispatch(resetClosingOrder());
      if (needQuit) {
        dispatch(setClosingCart(false, false));
      }
    };

    let obj = {
      url: '/api/shipmentcenter/closing/markorder',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore nel segnare spedito',
    };

    dispatch(apiCall(obj));
  };
};

export const getNextOrder = (idClosingCart) => {
  return (dispatch) => {
    let onThen = () => {};

    let obj = {
      url: '/api/shipmentcenter/closing/getnextorder',
      method: 'post',
      data: {idCart: idClosingCart},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore nel recupero del prossimo ordine',
    };

    dispatch(apiCall(obj));
  };
};

export const setShipmentCenterPanel = (idPanel) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_IDPANEL,
    idPanel: idPanel,
  };
};

export const setIsClosing = () => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_ISCLOSING,
  };
};

export const resetCart = () => {
  return {
    type: actionTypes.RESET_SHIPMENTCENTER_CART,
  };
};

export const setCartReadyForShipping = (idCart) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(resetCart());
    };

    let obj = {
      url: '/api/shipmentcenter/cart/setreadyforshipping',
      method: 'post',
      data: {idCart: idCart},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore nel settare il carrello pronto per la spedizione',
    };

    dispatch(apiCall(obj));
  };
};

export const selectCart = (idCart, cartName, wantQuit = false) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_SELECTEDCART,
    idCart: idCart,
    cartName: cartName,
    wantQuit: wantQuit,
  };
};

export const setClosingCart = (idCart, cartName) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_CLOSINGCART,
    idCart: idCart,
    cartName: cartName,
  };
};

export const setShipmentCenterIdState = (idState) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_IDSTATE,
    idState: idState,
  };
};

export const getProductToCollect = (data) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_START_COLLECTING,
    product: data.product,
  };
};

export const setNextProduct = (data) => {
  return {
    type: actionTypes.SET_COLLECTPROCESS_NEXTPRODUCTS,
    products: data.reservation,
    isClosing: data.isClosing || false,
    uuid: data.uuid,
  };
};

export const setCartInUse = (data) => {
  return {
    type: actionTypes.SET_COLLECTPROCESS_IDCART,
    idCart: data.idCart,
    cartName: data.cartName,
  };
};

export const setWarehouseShelvings = (data) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_SHELVINGS,
    shelvings: data.shelvings,
  };
};

export const setCreatedLabel = (data) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_CREATED_LABEL,
    labelUrl: data.label?.labelUrl,
  };
};

export const getWarehouseShelvings = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setWarehouseShelvings(response.data));
    };

    let obj = {
      url: '/api/shipmentcenter/shelvings',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero degli scaffali',
    };

    dispatch(apiCall(obj));
  };
};

export const confirmPick = (
  idCart,
  idProduct,
  idSku,
  idCombination,
  idShelf,
  quantity,
  partialValue,
  startTime,
  endTime,
  uuid,
  wantQuit,
  redirect
) => {
  return (dispatch) => {
    let onThen = (data) => {
      toast.info('Prodotto raccolto!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
      redirect(wantQuit);
      dispatch(emptyPickProductRow());
      dispatch(getNextProduct(idCart, data.position, wantQuit));
    };

    let obj = {
      url: '/api/shipmentcenter/cart/confirmpick',
      method: 'post',
      data: {
        idCart: idCart,
        idProduct: idProduct,
        idSku: idSku,
        idCombination: idCombination,
        idShelf: idShelf,
        quantity: quantity,
        partialValue: partialValue,
        startTime: startTime,
        endTime: endTime,
        uuid: uuid,
        wantQuit: wantQuit,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: "Errore durante l'inserimento nel carrello",
    };

    dispatch(apiCall(obj));
  };
};

export const changeCartState = (idCart, idState, idUser) => {
  return (dispatch) => {
    let onThen = () => {};

    let obj = {
      url: '/api/shipmentcenter/cart/changestate',
      method: 'post',
      data: {idCart: idCart, idState: idState, idUser: idUser},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante la chiusura del carrello',
    };

    dispatch(apiCall(obj));
  };
};

export const startCollecting = (idCart, startPosition, marketplaces_id) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setCartInUse(response.data));
    };

    let obj = {
      url: '/api/shipmentcenter/collect/start',
      method: 'post',
      data: {
        idCart: parseInt(idCart),
        startPosition: parseInt(startPosition),
        marketplaces_id: marketplaces_id,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: "Errore durante l'inizializzazione della raccolta",
    };

    dispatch(apiCall(obj));
  };
};

export const closePicking = (idCart, startPosition) => {
  return (dispatch) => {
    let onThen = (response) => {
      if (response.data.noProducts === false) {
        toast.warning(
          "Necessaria raccolta di alcuni prodotti...se vuoi fermarti chiama un'altra persona"
        );
      }
      if (response.data.isClosing) {
        // dispatch({
        //   type: actionTypes.SET_SHIPMENTCENTER_WANTQUIT,
        // });
      }
      dispatch(setNextProduct(response.data));
    };

    let obj = {
      url: '/api/shipmentcenter/collect/close',
      method: 'post',
      data: {idCart: idCart, startPosition: startPosition},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante la chiusura della raccolta',
    };

    dispatch(apiCall(obj));
  };
};

export const getNextProduct = (idCart, startPosition, wantQuit) => {
  return (dispatch) => {
    let onThen = () => {};

    let obj = {
      url: '/api/shipmentcenter/collect/nextproduct',
      method: 'post',
      data: {
        startPosition: startPosition,
        wantQuit: wantQuit,
        idCart: idCart,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante nel recupero del prossimo prodotto',
    };

    dispatch(apiCall(obj));
  };
};

export const fetchReservation = (uuid, type) => {
  return (dispatch) => {
    let onThen = (response) => {
      switch (type) {
        case 'picking':
          dispatch(setNextProduct(response.data));
          break;
        case 'closing':
          if (response?.data?.reservation?.unrecognized?.length > 0) {
            alert('Prodotti non riconosciuti rilevati');
          }
          dispatch(setClosingOrder(response.data));
          break;
        default:
          break;
      }
    };

    let obj = {
      url: '/api/shipmentcenter/collect/fetchreservation',
      method: 'post',
      data: {
        uuid: uuid,
        type: type,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante nel recupero della tua prenotazione',
    };

    dispatch(apiCall(obj));
  };
};

export const getOrdersByIdState = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setOrders(response.data));
    };

    let obj = {
      url: '/api/shipmentcenter/getbystate',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero degli ordini',
    };

    dispatch(apiCall(obj));
  };
};

export const setCarts = (data) => {
  return {
    type: actionTypes.SET_SHIPMENTCENTER_CARTS,
    carts: data.carts,
    idCartState: parseInt(data.idCartState),
  };
};

export const getCartsReadyForAction = (idCartState, changeCart = null) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setCarts(response.data));
    };

    let obj = {
      url: '/api/shipmentcenter/getcartsreadyforaction/' + idCartState,
      method: 'post',
      data: {changeCart: changeCart},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero dei carrelli',
    };

    dispatch(apiCall(obj));
  };
};

export const getBatchInShippingCarts = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setPickingBatchesForCarts(response.data));
    };

    let obj = {
      url: '/api/shipmentcenter/getpickingbatchesforcart/',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero dei carrelli da spedire',
    };

    dispatch(apiCall(obj));
  };
};

export const getPickingBatches = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setPickingBatches(response.data));
    };

    let obj = {
      url: '/api/shipmentcenter/collect/getpickingbatches',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero dei batch di raccolta',
    };

    dispatch(apiCall(obj));
  };
};

export const createManualLabel = (customer, address, idCarrier, idOrder) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setCreatedLabel(response.data));
    };

    let obj = {
      url: '/api/shipmentcenter/label/create',
      method: 'post',
      data: {
        customer: customer,
        address: address,
        idCarrier: idCarrier,
        idOrder: idOrder,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: "Errore durante la creazione dell'etichetta",
    };

    dispatch(apiCall(obj));
  };
};
