import {apiCall} from './apiCall';

export const getOrder = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let obj = {
        url: `/api/order/${id}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: "Errore durante il recupero dell'ordine",
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getPickingList = (idOrder) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order/${idOrder}/picking-list`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving picking list',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getProductsName = (name) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order/details/products/names?name=${encodeURIComponent(
          name
        )}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving product names',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getProductByBarcode = (value) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order/details/products/search/barcode?value=${encodeURIComponent(
          value
        )}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving product by barcode',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getProductByReference = (value) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order/details/products/search/reference?value=${encodeURIComponent(
          value
        )}`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving product by reference',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getOrderStates = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/order/states`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving order states',
      };
      dispatch(apiCall(obj));
    });
  };
};
export const getOrderHistory = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/orders/${id}/history`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: 'Error retrieving order history',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getOrderNotes = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/orders/${id}/notes`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: 'Error retrieving order notes',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const createOrderNote = (id, note) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Validate order id and note content
      if (!id) {
        reject('Order id is required.');
        return;
      }
      if (!note || note.trim() === '') {
        reject('The note field cannot be empty.');
        return;
      }

      const obj = {
        url: `/api/orders/${id}/note`,
        method: 'post',
        data: {note},
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: 'Order note created successfully',
        errorMessage: 'Error creating order note',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getCountries = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/countries`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        loader: false,
        noPendingRequest: true,
        successMessage: null,
        errorMessage: 'Error retrieving countries',
      };
      dispatch(apiCall(obj));
    });
  };
};
export const getProvinces = (idCountry) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const url = `/api/provinces${idCountry ? '?idCountry=' + idCountry : ''}`;
      const obj = {
        url,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: 'Error retrieving provinces',
      };
      dispatch(apiCall(obj));
    });
  };
};
export const getCarriers = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/carriers`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: 'Error retrieving carriers',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getCurrencies = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/currencies`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: 'Error retrieving currencies',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getPayments = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const obj = {
        url: `/api/payments`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: 'Error retrieving payments',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const getMaxDdtNumber = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let obj = {
        url: `/api/orders/max-ddt-number`,
        method: 'get',
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: 'Errore durante il recupero del numero DDT',
      };
      dispatch(apiCall(obj));
    });
  };
};

export const setDdtNumber = (orderId, ddtNumber) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let obj = {
        url: `/api/orders/set-ddt-number`,
        method: 'post',
        data: {
          idOrder: orderId,
          ddtNumber: ddtNumber,
        },
        onSuccess: (response) => resolve(response.data),
        onFailure: (error) => reject(error),
        label: '',
        headers: {},
        successMessage: null,
        errorMessage: "Errore durante l'inserimento del numero DDT",
      };
      dispatch(apiCall(obj));
    });
  };
};
